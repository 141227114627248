/** *************************************************************************************************
 * Load `$localize` onto the global scope - used if i18n tags appear in Angular templates.
 */
import '@angular/localize/init';
import 'core-js/es/reflect';
import 'zone.js/dist/zone';

declare global {
  interface Window {
    process: any;
    Buffer: any;
  }
}

(window as any).global = window;
window.process = {
  env: { DEBUG: undefined },
};
window.Buffer = window.Buffer || require('buffer/').Buffer;
